/* @ts-ignore */

import React from 'react';
import logo from './palavernights.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <section id="title">
        <div id="logo">
          <img src={logo} className="App-logo" alt="Palaver Nights Logo" />
        </div>
      </section>
      <h1>PALAVER NIGHTS</h1>
      <section className="btn-section">
        <div>
          
          <iframe src="https://discord.com/widget?id=952319269708894259&theme=dark" width="350" height="500" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div>
      </section>
    </div>
  );
}

export default App;
